import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import { ClientJS } from 'clientjs';

const client = new ClientJS();
const language = client.getLanguage();

const languageDetector = {
    type: 'languageDetector',
    async: true,
    detect: (cb) => cb(language ?? 'ru'),
}

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        resources: {
            "kk_KZ": {
                "translations": {
                    "Далее":"Әрі қарай",
                    "Код домофона": "Интерком коды",
                    "Название адреса": "Мекенжай аты",
                    "Добавить адреса": "Мекенжай қосу",
                    "Введите адрес": "Мекенжайды енгізіңіз",
                    "Введите дом": "Үйге кір",
                    "Введите корпус": "Блокты енгізіңіз",
                    "Введите подъезд": "Кіруге кіру",
                    "Введите квартиру": "Пәтерге кіру",
                    "Введите этаж": "Еденге кіру",
                    "Введите код": "Кодты енгізіңіз",
                    "Например 'Дом'": "Мысалы 'Үй'",
                    "Введите комментарий (Необязательно)": "Пікірді енгізіңіз (Қосымша)",
                    "обязательные поля для заполнения": "міндетті өрістер",
                    "Адрес по умолчанию": "Әдепкі мекенжай",
                    "В корзину":"Себетке қосу",
                    "Перейти на главную":"Басты бетке өту",
                    "Итоговая сумма":"Жалпы сома",
                    "Стоимость товаров":"Тауардың құны",
                    "товар":"өнім",
                    "Вы добавили":"Сіз қостыңыз",
                    "Очистить":"Таза",
                    "Бесплатная доставка":"Жеткізу тегін",
                    "Бесплатно от":"Тегін",
                    "Сохранить адрес":"Мекенжайды сақтау",
                    "Поиск адреса":"Мекенжай іздеу",
                    "Укажите номер дома":"Үй нөмірін енгізу",
                    "Редактировать адрес":"Мекенжайды өзгерту",
                    "Выбрать": "Таңдау",
                    "Закажу здесь": "Осында тапсырыс беремін",
                    "Выберите город": "Қаланы таңдау",
                    "Выбрать заведение": "Мекеме таңдау",
                    "Изменить": "Өзгерту",
                    "Настройки": "Параметрлер",
                    "Вам может понравится":"Сізге ұнауы мүмкін",
                    "Поиск...":"Іздеу...",
                    "Попробуйте найти что-то другое":"Басқа нәрсе тауып көріңіз",
                    "Перейти":"Бару",
                    "Доставка и самовывоз":"Жеткізу және алып кету",
                    "Аккаунт":"Есептік жазба",
                    "Перейти в меню":"Мәзірге өту",
                    "Вернитесь в меню и добавляйте интересующие товары в этот список":"Мәзірге оралыңыз және осы тізімге қызықты өнімдерді қосыңыз",
                    "Избранных товаров нет":"Сүйікті өнімдер жоқ",
                    "Создайте новый адрес для доставки заказа":"Тапсырысты жеткізу үшін жаңа мекен-жай жасау",
                    "Адрес не добавлен":"Мекенжай қосылмаған",
                    "Акции":"Науқан",
                    "Заказов пока нет":"Тапсырыс әлі жоқ",
                    "Выйти":"Шығу",
                    "Вы можете потратить":"Сіз жұмсай аласыз",
                    "Главная":"Үй",
                    "Личный кабинет":"Жеке аумақ",
                    "Восстановление пароля":"Парольді қалпына келтіру",
                    "Разработано на платформе":"Платформада әзірленген",
                    "Политика конфиденциальности":"Құпиялылық саясаты",
                    "Город": "Қала",
                    "Ваш баланс": "Сіздің балансыңыз",
                    "Ваш доход": "Сіздің табысыңыз",
                    "Профиль": "Профиль",
                    "Уведомления": "Хабарламалар",
                    "Пополнить": "Толықтыру",
                    "История": "Тарих",
                    "Посмотреть рекламу": "Жарнама қарау",
                    "Покупки": "Сатып алулар",
                    "Чат с поддержкой": "Қолдаумен сөйлесу",
                    "Оценить приложение": "Өтінімге баға беріңіз",
                    "О приложениии": "Өтінім туралы",
                    "Выйти из аккаунта": "Есептік жазбадан шығу",
                    "Подтверждение": "Растау",
                    "Версия": "Нұсқа",
                    "Вы точно хотите выйти из аккаунта?": "Есептік жазбаңыздан шынымен шыққыңыз келе ме?",
                    "Отмена": "Болдырмау",
                    "Жарайды": "Жарайды",
                    "Введите сумму пополнения": "Толықтыру сомасын енгізіңіз",
                    "Обязательное поле": "Міндетті өріс",
                    "Максимум": "Максимум",
                    "Минимум": "Ең аз",
                    "Я ознакомлен(-а) и принимаю условия оферты": "Мен ұсыныс шарттарын оқыдым және қабылдаймын",
                    "Перейти к оплате": "Төлемге өту",
                    "Нажмите чтобы скопировать": "Көшіру үшін басыңыз",
                    "Скопировано в буфер": "Буферге көшірілді",
                    "Номер телефона": "Телефон нөмірі",
                    "Войти в профиль": "Профильге кіру",
                    "Введите логин": "Логинді енгізіңіз",
                    "Введите пароль": "Құпия сөзді енгізіңіз",
                    "Забыли пароль?": "Құпия сөзіңізді ұмыттыңыз ба?",
                    "Войти": "Кіру",
                    "У вас нет аккаунта?": "Сізде аккаунт жоқ па?",
                    "Создать профиль": "Профиль жасау",
                    "Подтвердите удаление": "Жоюды растау",
                    "У вас пока нет избранных": "Сізде әлі таңдаулылар жоқ",
                    "Перейдите к списку, чтобы добавить публикацию в список избранных": "Таңдаулылар тізіміне жазба қосу үшін тізімге өтіңіз",
                    "или": "немесе",
                    "Войти через Telegram": "Telegram арқылы кіру",
                    "Перейдите в телеграм бот. Вам будет отправлен 4-х значный код подтверждения.": "Telegram ботына өтіңіз. Сізге 4 сандық растау коды жіберіледі.",
                    "Выберите страну": "Елді таңдау",
                    "Введите номер телефона": "Телефон нөмірін енгізіңіз",
                    "Перейти в бот": "Ботқа өту",
                    "Код подтверждения придет в телеграмм боте": "Растау коды телеграмма ботына жіберіледі",
                    "Подтверждая регистрацию, вы принимаете условия согласия на обработку персональных данных": "Тіркеуді растау арқылы сіз жеке деректерді өңдеуге келісім беру шарттарын қабылдайсыз",
                    "Уже есть аккаунт?": "Тіркелгі бар ма?",
                    "Страна": "Ел",
                    "Зарегистрироваться": "Тіркелу",
                    "У вас уже есть аккаунт?": "Сізде аккаунт бар ма?",
                    "Код подтверждения отправлен на указанный номер телефона": "Растау коды көрсетілген телефон нөміріне жіберілді",
                    "Восстановить пароль": "Құпия сөзді қалпына келтіру",
                    "Сохранить новый пароль": "Жаңа құпия сөзді сақтау",
                    "Пароль успешно изменен": "Пароль сәтті өзгертілді",
                    "Теперь войдите в свой профиль через форму авторизации.": "Енді авторизация пішіні арқылы профиліңізге кіріңіз.",
                    "Перейти ко входу": "Кіруге өту",
                    "Придумайте новый пароль": "Жаңа құпия сөзді жасау",
                    "Отправить код повторно": "Кодты қайта жіберу",
                    "сек": "сек",
                    "Повторите пароль": "Құпия сөзді қайталау",
                    "Мы отправили код подтверждения на указанную почту": "Біз көрсетілген электрондық поштаға растау кодын жібердік",
                    "Активация аккаунта": "Есептік жазбаны белсендіру",
                    "Вы успешно активированы": "Сіз сәтті қосылдыңыз",
                    "Уведомление": "Хабарлама",
                    "Закладки": "Таңдаулылар",
                    "Описание": "Сипаттамасы",
                    "Продолжить": "Жалғастыру",
                    "Редактировать профиль": "Профильді өңдеу",
                    "Сохранить изменения": "Өзгерістерді сақтау",
                    "Пол": "Гендер",
                    "Женский": "Әйел",
                    "Мужской": "Еркек",
                    "О себе": "туралы",
                    "Пару слов о себе...": "Өзім туралы бірер сөз...",
                    "Промокод": "Жарнамалық код",
                    "Удалить профиль": "Профильді жою",
                    "Нажмите чтобы скопировать. Передайте код другу, чтобы он ввел его при регистрации.": "Көшіру үшін басыңыз. Тіркелу кезінде кодты енгізуі үшін досыңызға кодты беріңіз.",
                    "После удаления данные можно будет восстановить в течении 30 дней. После истеченного периода профиль будет удален безвозвратно.": "Жойылғаннан кейін деректерді 30 күн ішінде қалпына келтіруге болады. Мерзім аяқталғаннан кейін профиль біржола жойылады." ,
                    "Введите дату рождения": "Туған күнін енгізіңіз",
                    "Сохранить": "Сақтау",
                    "После заполнения, изменить данные возраста будет невозможно. Данные видны только вам.": "Толтырылғаннан кейін жас деректерін өзгерту мүмкін болмайды. Деректер тек сізге көрінеді.",
                    "Дата рождения": "Туған күні",
                    "Указать номер телефона": "Телефон нөмірін көрсетіңіз",
                    "Указать email": "Электрондық поштаны көрсетіңіз",
                    "Введите имя": "Аты-жөніңізді енгізіңіз",
                    "Неизвестная ошибка": "Белгісіз қате",
                    "Введите код подтверждения": "Растау кодын енгізіңіз",
                    "Мы отправили код подтверждения на указанную почту. Если код не пришел в течении 5 мин, посмотрите в папке спама.": "Біз көрсетілген электрондық поштаға растау кодын жібердік. Егер код 5 минут ішінде келмесе, өзіңіздің жеке мекенжайыңызды қараңыз. спам қалтасы.",
                    "Электрондық пошта успешно изменен": "Электрондық пошта сәтті өзгертілді",
                    "Вернитесь назад, чтобы начать полноценно пользоваться приложением.": "Қолданбаны толығымен пайдалану үшін артқа оралыңыз.",
                    "Назад": "Артқа",
                    "Отправить": "Жіберу",
                    "Подтверждаю удаление": "Жоюды растаймын",
                    "Электрондық поштаны жіберу": "Электрондық поштаны енгізіңіз",
                    "Неверный формат почты": "Жарамсыз пошта пішімі",
                    "Россия": "Ресей",
                    "Қазақстан": "Қазақстан",
                    "Беларусь": "Беларусь",
                    "Өзбекстан": "Өзбекстан",
                    "Туркменистан": "Түркіменстан",
                    "Тәжікстан": "Тәжікстан",
                    "Қырғызстан": "Қырғызстан",
                    "Армения": "Армения",
                    "Азербайджан": "Әзербайжан",
                    "Русский": "Орысша",
                    "Английский": "Ағылшын",
                    "Казахский": "Қазақ",
                    "Выберите язык": "Тілді таңдау",
                    "Доступно новое обновление": "Жаңа жаңарту қолжетімді",
                    "Перейдите в маркет для скачивания новой версии приложения": "Қолданбаның жаңа нұсқасын жүктеп алу үшін нарыққа барыңыз",
                    "Обновить приложение": "Қолданбаны жаңарту",
                    "Язык": "Тіл",
                    "День рождения": "Туған күн",
                    "Имя": "Аты-жөні",
                    "Пользовательское соглашение": "Пайдаланушы келісімі",
                    "Политика о конфиденциальности": "Құпиялылық саясаты",
                    "Правила публикации": "Жариялау ережелері",
                    "Публичная оферта": "Жария ұсыныс",
                    "Доступно в": "Қол жетімді",
                    "Публикации": "Жарияланымдар",
                    "Связаться с нами": "Бізбен байланыс",
                    "Язык успешно изменен": "Тіл сәтті өзгертілді",
                    "Изменения успешно сохранены": "Өзгертулер сәтті сақталды",
                    "из": "of",
                    "Пополнить счет": "Тіркелгіңізді толтырыңыз",
                    "Я согласен(-на) на обработку": "Мен өңдеуге келісемін",
                    "персональных данных": "жеке деректер",
                    "История операций": "Операциялар тарихы",
                    "Пополнение": "Толықтыру",
                    "Введите сообщение": "Хабарыңызды енгізіңіз",
                    "Тех поддержка": "Техникалық қолдау",
                    "Купить": "Сатып алу",
                    "Покупка": "Сатып алу",
                    "Попробуйте зайти позже": "Кейінірек кіріп көріңіз",
                    "Перейти в каталог": "Каталогқа өту",
                    "Списать": "Өшіру",
                    "с вашего баланса?": "балансыңыздан?",
                    "Сначала авторизуйтесь": "Алдымен кіріңіз",
                    "Задать вопрос": "Сұрақ қою",
                    "договора оферты": "ұсыныс келісімі",
                    "Я согласен(-на) с условиями": "Мен шарттармен келісемін",
                    "Нашли ошибку в тексте?": "Мәтіннен қате таптыңыз ба?",
                    "Напишите нам": "Бізге жазыңыз",
                    "Данные видите только вы": "Деректерді тек сіз көре аласыз",
                    "Избранное":"Таңдаулылар",
                    "Адрес":"Мекенжай",
                    "Заказы":"Тапсырыстар",
                    "Жоба":"Жоба",
                    "Имя":"Аты-жөні",
                    "Фамилия":"Тегі",
                    "Введите старый пароль":"Ескі құпия сөзді енгізіңіз",
                    "Введите новый пароль":"Жаңа құпия сөзді енгізіңіз",
                    "Новый email":"Жаңа электрондық поштаны енгізіңіз",
                    "У вас пока нет заказов":"Сізде әлі тапсырыстар жоқ",
                    "Перейдите к меню, чтобы сделать первый заказ":"Бірінші тапсырыс беру үшін мәзірге өтіңіз",
                    "Номер заказа":"Тапсырыс нөмірі",
                    "Время заказа":"Тапсырыс уақыты",
                    "Ко времени":"Сол кезде",
                    "Способ получения":"Түбіртек әдісі",
                    "Способ оплаты":"Төлем әдісі",
                    "Приборов":"Құрылғы",
                    "Адрес доставки":"Жеткізу мекенжайы",
                    "корпус":"блок",
                    "подъезд":"кіру",
                    "этаж":"қабат",
                    "кв":"пәтер",
                    "Самовывоз":"Алып кету",
                    "Нет информации":"Ақпарат жоқ",
                    "Комментарий":"Пікір",
                    "Позвонить":"Қоңырау шалу",
                    "Вопрос по заказу":"Тапсырыс туралы сұрақ",
                    "Написать":"Жазу",
                    "Детали заказа":"Тапсырыс мәліметтері",
                    "шт":"ДК",
                    "Списание баллов":"Ұпайларды есептен шығару",
                    "Начислится баллов":"Ұпайлар беріледі",
                    "Доставка":"Жеткізу",
                    "Скидка за самовывоз":"Алып кетуге жеңілдік",
                    "Итого":"Барлығы",
                    "У вас":"Сіз",
                    "бонус":"бонус",
                    "бонуса":"бонус",
                    "бонусов":"бонустар",
                    "Жеке номер":"Жеке нөмір",
                    "У вас нет истории":"Сізде тарих жоқ",
                    "Бонусная карта":"Бонус картасы",
                    "Помощь":"Көмек",
                    "Чат с оператором":"Оператормен сөйлесу",
                    "Адреса":"Мекенжайлар",
                    "У вас пока нет адресов":"Сізде әлі мекенжайлар жоқ",
                    "Вы можете добавить новый адрес доставки нажам на кнопку +":"Сіз + түймесін басу арқылы жаңа жеткізу мекенжайын қоса аласыз",
                    "Название":"Аты-жөні",
                    "Максимальное кол-во символов 250":"Таңбалардың ең көп саны 250",
                    "Дом":"Үй",
                    "Максимальное кол-во символов 10":"Таңбалардың ең көп саны 10",
                    "Корпус":"Блок",
                    "Подъезд":"Кіру",
                    "Этаж":"Еден",
                    "Максимальное кол-во символов 3":"Таңбалардың ең көп саны 3",
                    "Квартира":"Пәтер",
                    "Адрес выбран по умолчанию":"Мекенжай әдепкі бойынша таңдалған",
                    "Адрес не выбран по умолчанию":"Мекенжай әдепкі бойынша таңдалмаған",
                    "Вы точно хотите удалить адрес?":"Мекенжайды шынымен жойғыңыз келе ме?",
                    "Подтверждение":"Растау",
                    "Удалить":"Жою",
                    "У вас пока нет избранных":"Сізде әлі таңдаулылар жоқ",
                    "Перейдите к меню, чтобы добавить товары в список избранных":"Таңдаулылар тізіміне өнімдерді қосу үшін мәзірге өтіңіз",
                    "Онлайн":"Онлайн",
                    "Мы работаем с":"Біз жұмыс істейміз",
                    "Оффлайн":"Офлайн",
                    "У вас пока нет обращений":"Сізде әлі сұрау жоқ",
                    "Мы ответим вам в течении пару минут":"Бір-екі минут ішінде жауап береміз",
                    "Сначала войдите в свой профиль":"Алдымен профиліңізге кіріңіз",
                    "Поддержка временна не работает":"Қолдау уақытша қолжетімсіз",
                    "Начните писать сообщение":"Хабар жазуды бастау",
                    "Каталог пуст":"Каталог бос",
                    "Категория":"Санат",
                    "Товаров нет":"Өнім жоқ",
                    "Попробуйте зайти чуть позже":"Біраз уақыттан кейін қайтып көріңіз",
                    "Онлайн оплата":"Онлайн төлем",
                    "Банковской картой":"Банк картасы",
                    "Наличными":"Қолма-қол ақша",
                    "Добавьте адрес доставки":"Жеткізу мекенжайын қосу",
                    "По данному адресу доставка не производится":"Бұл мекенжайға жеткізу мүмкін емес",
                    "Неизвестная ошибка":"Белгісіз қате",
                    "Войдите в профиль":"Профильге кіру",
                    "Войдите в свой профиль чтобы сделать заказ":"Тапсырыс беру үшін профиліңізге кіріңіз",
                    "Заказ успешно оформлен":"Тапсырыс сәтті аяқталды",
                    "Статус заказа вы можете узнать в личном профиле, в разделе Заказы.":"Тапсырысыңыздың күйін жеке профиліңізде, Тапсырыстар бөлімінен біле аласыз.",
                    "Продолжить покупки":"Сауданы жалғастыру",
                    "Корзина пуста":"Арба бос",
                    "Перейдите к меню, чтобы сделать первый заказ":"Бірінші тапсырыс беру үшін мәзірге өтіңіз",
                    "Заведение сейчас не работает":"Мекеме қазіргі уақытта жабық",
                    "Зайдите к нам немного позже":"Сәл кейінірек келіңіздер",
                    "Выберите адрес доставки":"Жеткізу мекенжайын таңдау",
                    "Adres camovыvoza":"Алу мекенжайы",
                    "Тип оплаты": "Төлем түрі",
                    "Нет типов оплат": "Төлем түрлері жоқ",
                    "Время доставки": "Жеткізу уақыты",
                    "Время подачи": "Қызмет көрсету уақыты",
                    "Сохранить": "Сақтау",
                    "Отмена": "Болдырмау",
                    "Скидка": "Жеңілдік",
                    "Сумма": "Қосынды",
                    "Промокод": "Жарнамалық код",
                    "Бесплатно": "Тегін",
                    "Оформить заказ": "Тексеру",
                    "Корзина": "Себет",
                    "Смена пароля": "Парольді өзгерту",
                    "Для подтверждения удаления, введите пароль от данного аккаунта": "Жоюды растау үшін осы есептік жазбаның құпия сөзін енгізіңіз",
                    "Уведомлений нет":"Хабарламалар жоқ",
                    "Спецпредложения":"Арнайы ұсыныстар",
                    "Спецпредложение":"Арнайы ұсыныс",
                    "Введите промокод":"Жарнамалық кодты енгізіңіз",
                    "Применить":"Қолдану",
                    "Дәлее":"Ары қарай",
                    "Вы точно хотите очистить корзину?":"Қоқыс жәшігін босатқыңыз келетініне сенімдісіз бе?",
                    "Оформление заказа":"Тапсырыс беру",
                    "Сейчас":"Қазір",
                    "Поиск":"Іздеу",
                    "Начните вводить текст":"Теруді бастау",
                    "Ничего не найдено":"Ештеңе табылмады",
                    "Введите название или состав товара":"Өнімнің атын немесе құрамын енгізіңіз",
                    "Тіркеу":"Тіркеу",
                    "Выбрать тип оплаты": "Төлем түрін таңдау",
                    "Выбрать адрес": "Мекенжайды таңдау",
                    "Создать адрес": "Мекенжай жасау",
                    "Доставка не производится":"Жеткізу мүмкін емес",
                    "Изменить адрес":"Мекенжайды өзгерту",
                    "Нет номера дома":"Үй нөмірі жоқ",
                    "Добавить адрес":"Мекенжай қосу",
                    "Заполните форму":"Форманы толтыру",
                    "Полный адрес":"Толық мекенжай",
                    "Стоимость доставки":"Жеткізу құны",
                    "Бесплатная доставка":"Жеткізу тегін",
                    "Бесплатно от":"Тегін",
                    "Сохранить адрес":"Мекенжайды сақтау",
                    "Поиск адреса":"Мекенжай іздеу",
                    "Укажите номер дома":"Үй нөмірін енгізу",
                    "Редактировать адрес":"Мекенжайды өзгерту",
                    "Выбрать": "Таңдау",
                    "Закажу здесь": "Осында тапсырыс беремін",
                    "Выберите город": "Қаланы таңдау",
                    "Выбрать заведение": "Мекеме таңдау",
                    "Контакты":"Контактілер",
                }
            },
            "en": {
                "translations": {
                    "Код домофона": "Intercom code",
                    "В корзину":"Add to cart",
                    "Перейти на главную":"Go to Main page",
                    "Итоговая сумма":"Total amount",
                    "Стоимость товаров":"Cost of goods",
                    "товар":"product",
                    "Вы добавили":"You added",
                    "Очистить":"Clear",
                    "Название адреса": "Address name",
                    "Добавить адреса": "Add addresses",
                    "Введите адрес": "Enter address",
                    "Введите дом": "Enter house",
                    "Введите корпус": "Enter block",
                    "Введите подъезд": "Enter entrance",
                    "Введите квартиру": "Enter apartment",
                    "Введите этаж": "Enter floor",
                    "Введите код": "Enter a code",
                    "Например 'Дом'": "For example 'Home'",
                    "Введите комментарий (Необязательно)": "Enter a comment (Optional)",
                    "обязательные поля для заполнения": "required fields",
                    "Адрес по умолчанию": "Default address",
                    "Изменить": "Edit",
                    "Настройки": "Settings",
                    "Вам может понравится":"You may like",
                    "Поиск...":"Search...",
                    "Попробуйте найти что-то другое":"Try to find something else",
                    "Перейти":"Go",
                    "Доставка и самовывоз":"Delivery and pickup",
                    "Аккаунт":"Account",
                    "Перейти в меню":"Go to menu",
                    "Вернитесь в меню и добавляйте интересующие товары в этот список":"Go back to the menu and add products of interest to this list",
                    "Избранных товаров нет":"There are no favorite products",
                    "Создайте новый адрес для доставки заказа":"Create a new address for delivery of the order",
                    "Адрес не добавлен":"Address not added",
                    "Акции":"Stock",
                    "Заказов пока нет":"No orders yet",
                    "Выйти":"Go out",
                    "Вы можете потратить":"You can spend",
                    "Главная":"Home",
                    "Личный кабинет":"Personal Area",
                    "Восстановление пароля":"Password recovery",
                    "Разработано на платформе":"Developed on the platform",
                    "Политика конфиденциальности":"Privacy Policy",
                    "Контакты":"Contacts",
                    "Придумайте пароль":"Create a password",
                    "Принять условия Пользовательского соглашения":"Accept the terms of the User Agreement",
                    "Подтверждение пароля":"Password confirmation",
                    "Введи данные, чтобы зарегистрироваться":"Enter your details to register",
                    "Привет, друг!":"Hi, friend!",
                    "Войти в личный кабинет":"Login to your personal account",
                    "Пройдите регистрацию":"Please register",
                    "Это ваш первый заказ?": "Is this your first order?",
                    "С возвращением!": "Welcome back!",
                    "Пароль": "Password",
                    "Город": "City",
                    "Ваш баланс": "Your balance",
                    "Ваш доход": "Your income",
                    "Профиль": "Profile",
                    "Уведомления": "Notifications",
                    "Пополнить": "Top up",
                    "История": "History",
                    "Посмотреть рекламу": "Watch advertisement",
                    "Покупки": "Purchases",
                    "Чат с поддержкой": "Chat with support",
                    "Оценить приложение": "Rate the application",
                    "О приложении": "About the application",
                    "Выйти из аккаунта": "Log out of account",
                    "Подтверждение": "Confirmation",
                    "Версия": "Version",
                    "Номер телефона": "Phone number",
                    "Вы точно хотите выйти из аккаунта?": "Are you sure you want to log out of your account?",
                    "Отмена": "Cancel",
                    "Ок": "Ok",
                    "Введите сумму пополнения": "Enter the top-up amount",
                    "Обязательное поле": "Required field",
                    "Максимум": "Maximum",
                    "Минимум": "Minimum",
                    "Я ознакомлен(-а) и принимаю условия оферты": "I have read and accept the terms of the offer",
                    "Перейти к оплате": "Proceed to payment",
                    "Нажмите чтобы скопировать": "Click to copy",
                    "Скопировано в буфер": "Copied to clipboard",
                    "Войти в профиль": "Login to profile",
                    "Введите логин": "Enter login",
                    "Введите пароль": "Enter password",
                    "Забыли пароль?": "Forgot your password?",
                    "Войти": "Login",
                    "У вас нет аккаунта?": "You don't have an account?",
                    "Создать профиль": "Create a profile",
                    "Подтвердите удаление": "Confirm deletion",
                    "У вас пока нет избранных": "You don't have any favorites yet",
                    "Перейдите к списку, чтобы добавить публикацию в список избранных": "Go to the list to add a post to your favorites list",
                    "или": "or",
                    "Войти через Telegram": "Log in via Telegram",
                    "Перейдите в телеграм бот. Вам будет отправлен 4-х значный код подтверждения.": "Go to the telegram bot. You will be sent a 4-digit confirmation code.",
                    "Выберите страну": "Choose a country",
                    "Введите номер телефона": "Enter phone number",
                    "Перейти в бот": "Go to bot",
                    "Код подтверждения придет в телеграмм боте": "The confirmation code will be sent to the telegram bot",
                    "Подтверждая регистрацию, вы принимаете условия согласия на обработку персональных данных": "By confirming registration, you accept the terms of consent to the processing of personal data",
                    "Уже есть аккаунт?": "Already have an account?",
                    "Страна": "A country",
                    "Зарегистрироваться": "Register",
                    "У вас уже есть аккаунт?": "Do you already have an account?",
                    "Код подтверждения отправлен на указанный номер телефона": "Confirmation code has been sent to the specified phone number",
                    "Восстановить пароль": "Restore password",
                    "Сохранить новый пароль": "Save new password",
                    "Пароль успешно изменен": "Password changed successfully",
                    "Теперь войдите в свой профиль через форму авторизации.": "Now log in to your profile through the authorization form.",
                    "Перейти ко входу": "Go to login",
                    "Придумайте новый пароль": "Create a new password",
                    "Отправить код повторно": "Resend code",
                    "сек": "sec",
                    "Повторите пароль": "Repeat password",
                    "Мы отправили код подтверждения на указанную почту": "We have sent a confirmation code to the specified email",
                    "Активация аккаунта": "Account activation",
                    "Вы успешно активированы": "You have been successfully activated",
                    "Уведомление": "Notification",
                    "Закладки": "Favorites",
                    "Описание": "Description",
                    "Продолжить": "Continue",
                    "Редактировать профиль": "Edit profile",
                    "Сохранить изменения": "Save changes",
                    "Пол": "Gender",
                    "Женский": "Female",
                    "Мужской": "Male",
                    "О себе": "About",
                    "Пару слов о себе...": "A few words about myself...",
                    "Промокод": "Promotional code",
                    "Удалить профиль": "Delete profile",
                    "Нажмите чтобы скопировать. Передайте код другу, чтобы он ввел его при регистрации.": "Click to copy. Pass the code to a friend so that he can enter it when registering.",
                    "После удаления данные можно будет восстановить в течении 30 дней. После истеченного периода профиль будет удален безвозвратно.": "After deletion, the data can be restored within 30 days. After the expiration of the period, the profile will be deleted permanently.",
                    "Введите дату рождения": "Enter date of birth",
                    "Сохранить": "Save",
                    "После заполнения, изменить данные возраста будет невозможно. Данные видны только вам.": "Once filled out, it will be impossible to change the age data. The data is visible only to you.",
                    "Дата рождения": "Date of Birth",
                    "Указать номер телефона": "Specify phone number",
                    "Указать email": "Specify email",
                    "Введите имя": "Enter your name",
                    "Неизвестная ошибка": "Unknown error",
                    "Введите код подтверждения": "Enter confirmation code",
                    "Мы отправили код подтверждения на указанную почту. Если код не пришел в течении 5 мин, посмотрите в папке спама.": "We have sent a confirmation code to the specified email. If the code has not arrived within 5 minutes, look in your spam folder.",
                    "Email успешно изменен": "Email successfully changed",
                    "Вернитесь назад, чтобы начать полноценно пользоваться приложением.": "Go back to start fully using the application.",
                    "Назад": "Back",
                    "Отправить": "Send",
                    "Подтверждаю удаление": "I confirm deletion",
                    "Введите email": "Enter email",
                    "Неверный формат почты": "Invalid mail format",
                    "Россия": "Russia",
                    "Казахстан": "Kazakhstan",
                    "Беларусь": "Belarus",
                    "Узбекистан": "Uzbekistan",
                    "Туркменистан": "Turkmenistan",
                    "Таджикистан": "Tajikistan",
                    "Кырыгызтан": "Kyrygyztan",
                    "Армения": "Armenia",
                    "Азербайджан": "Azerbaijan",
                    "Русский": "Russian",
                    "Английский": "English",
                    "Казахский": "Kazakh",
                    "Выберите язык": "Choose language",
                    "Доступно новое обновление": "New update available",
                    "Перейдите в маркет для скачивания новой версии приложения": "Go to the market to download the new version of the application",
                    "Обновить приложение": "Update application",
                    "Язык": "Language",
                    "День рождения": "Birthday",
                    "Имя": "Firstname",
                    "Пользовательское соглашение": "User Agreement",
                    "Политика о конфиденциальности": "Privacy Policy",
                    "Правила публикации": "Publishing Rules",
                    "Публичная оферта": "Public offer",
                    "Доступно в": "Available in",
                    "Публикации": "Publications",
                    "Связаться с нами": "Connect with us",
                    "Язык успешно изменен": "Language changed successfully",
                    "Изменения успешно сохранены": "Changes saved successfully",
                    "из": "of",
                    "Пополнить счет": "Top up your account",
                    "Я согласен(-на) на обработку": "I agree to processing",
                    "персональных данных": "personal data",
                    "История операций": "Operations history",
                    "Пополнение": "Replenishment",
                    "Введите сообщение": "Enter your message",
                    "Тех поддержка": "Tech support",
                    "Купить": "Buy",
                    "Покупка": "Purchase",
                    "Попробуйте зайти позже": "Try to log in later",
                    "Перейти в каталог": "Go to directory",
                    "Списать": "Write off",
                    "с вашего баланса?": "from your balance?",
                    "Сначала авторизуйтесь": "Log in first",
                    "Задать вопрос": "Ask a Question",
                    "договора оферты": "offer agreement",
                    "Я согласен(-на) с условиями": "I agree to the terms and conditions",
                    "Нашли ошибку в тексте?": "Found an error in the text?",
                    "Напишите нам": "Write to us",
                    "Данные видите только вы": "Only you can see the data",
                    "Избранное":"Favorites",
                    "Адрес":"Address",
                    "Заказы":"Orders",
                    "Проект":"Project",
                    "Имя":"Name",
                    "Фамилия":"Surname",
                    "Введите старый пароль":"Enter old password",
                    "Введите новый пароль":"Enter a new password",
                    "Введите новый email":"Enter new email",
                    "У вас пока нет заказов":"You have no orders yet",
                    "Перейдите к меню, чтобы сделать первый заказ":"Go to the menu to place your first order",
                    "Номер заказа":"Order number",
                    "Время заказа":"Order time",
                    "Ко времени":"At the time",
                    "Способ получения":"Receipt method",
                    "Способ оплаты":"Payment method",
                    "Приборов":"Device",
                    "Адрес доставки":"Delivery address",
                    "корпус":"block",
                    "подъезд":"entrance",
                    "этаж":"floor",
                    "кв":"apartment",
                    "Самовывоз":"Pickup",
                    "Нет информации":"No information",
                    "Комментарий":"A comment",
                    "Позвонить":"Call",
                    "Вопрос по заказу":"Question about the order",
                    "Написать":"Write",
                    "Детали заказа":"Order details",
                    "шт":"PC",
                    "Списание баллов":"Write off points",
                    "Начислится баллов":"Points will be awarded",
                    "Доставка":"Delivery",
                    "Скидка за самовывоз":"Discount for pickup",
                    "Итого":"Total",
                    "У вас":"You",
                    "бонус":"bonus",
                    "бонуса":"bonus",
                    "бонусов":"bonuses",
                    "Персональный номер":"Personal number",
                    "У вас нет истории":"You have no history",
                    "Бонусная карта":"Bonus card",
                    "Помощь":"Help",
                    "Чат с оператором":"Chat with operator",
                    "Адреса":"Addresses",
                    "У вас пока нет адресов":"You don't have any addresses yet",
                    "Вы можете добавить новый адрес доставки нажам на кнопку +":"You can add a new delivery address by clicking on the + button",
                    "Название":"Name",
                    "Максимальное кол-во символов 250":"Maximum number of characters 250",
                    "Дом":"House",
                    "Максимальное кол-во символов 10":"Maximum number of characters 10",
                    "Корпус":"Block",
                    "Подъезд":"Entrance",
                    "Этаж":"Floor",
                    "Максимальное кол-во символов 3":"Maximum number of characters 3",
                    "Квартира":"Apartment",
                    "Адрес выбран по умолчанию":"Address selected by default",
                    "Адрес не выбран по умолчанию":"Address not selected by default",
                    "Вы точно хотите удалить адрес?":"Are you sure you want to delete the address?",
                    "Подтверждение":"Confirmation",
                    "Удалить":"Delete",
                    "У вас пока нет избранных":"You don't have any favorites yet",
                    "Перейдите к меню, чтобы добавить товары в список избранных":"Go to the menu to add products to your favorites list",
                    "Онлайн":"Online",
                    "Мы работаем с":"We work with",
                    "Оффлайн":"Offline",
                    "У вас пока нет обращений":"You have no requests yet",
                    "Мы ответим вам в течении пару минут":"We will answer you within a couple of minutes",
                    "Сначала войдите в свой профиль":"Login to your profile first",
                    "Поддержка временна не работает":"Support is temporarily unavailable",
                    "Начните писать сообщение":"Start writing a message",
                    "Каталог пуст":"Directory is empty",
                    "Категория":"Category",
                    "Товаров нет":"There are no products",
                    "Попробуйте зайти чуть позже":"Try to come back a little later",
                    "Онлайн оплата":"Online payment",
                    "Банковской картой":"Bank card",
                    "Наличными":"Cash",
                    "Добавьте адрес доставки":"Add delivery address",
                    "По данному адресу доставка не производится":"Delivery is not available to this address",
                    "Неизвестная ошибка":"Unknown error",
                    "Войдите в профиль":"Log in to your profile",
                    "Войдите в свой профиль чтобы сделать заказ":"Login to your profile to place an order",
                    "Заказ успешно оформлен":"Order successfully completed",
                    "Статус заказа вы можете узнать в личном профиле, в разделе Заказы.":"You can find out the status of your order in your personal profile, in the Orders section.",
                    "Продолжить покупки":"Continue shopping",
                    "Корзина пуста":"Cart is empty",
                    "Перейдите к меню, чтобы сделать первый заказ":"Go to the menu to place your first order",
                    "Заведение сейчас не работает":"The establishment is currently closed",
                    "Зайдите к нам немного позже":"Come see us a little later",
                    "Выберите адрес доставки":"Select delivery address",
                    "Адрес cамовывоза":"Pickup address",
                    "Тип оплаты": "Payment type",
                    "Нет типов оплат": "No payment types",
                    "Время доставки": "Delivery time",
                    "Время подачи": "Service time",
                    "Сохранить": "Save",
                    "Отмена": "Cancel",
                    "Скидка": "Discount",
                    "Сумма": "Sum",
                    "Промокод": "Promotional code",
                    "Бесплатно": "For free",
                    "Оформить заказ": "Checkout",
                    "Корзина": "Basket",
                    "Смена пароля": "Change Password",
                    "Для подтверждения удаления, введите пароль от данного аккаунта": "To confirm deletion, enter the password for this account",
                    "Уведомлений нет":"No notifications",
                    "Спецпредложения":"Special offers",
                    "Спецпредложение":"Special offer",
                    "Введите промокод":"Enter promotional code",
                    "Применить":"Apply",
                    "Далее":"Further",
                    "Вы точно хотите очистить корзину?":"Are you sure you want to empty the trash can?",
                    "Оформление заказа":"Place an order",
                    "Сейчас":"Now",
                    "Поиск":"Search",
                    "Начните вводить текст":"Start typing",
                    "Ничего не найдено":"Nothing found",
                    "Введите название или состав товара":"Enter the name or composition of the product",
                    "Регистрация":"Registration",
                    "Выбрать тип оплаты": "Select payment type",
                    "Выбрать адрес": "Select address",
                    "Создать адрес": "Create address",
                    "Доставка не производится":"Delivery is not available",
                    "Изменить адрес":"Change address",
                    "Нет номера дома":"No house number",
                    "Добавить адрес":"Add address",
                    "Заполните форму":"Fill the form",
                    "Полный адрес":"Full address",
                    "Стоимость доставки":"Cost of delivery",
                    "Бесплатная доставка":"Free shipping",
                    "Бесплатно от":"Free from",
                    "Сохранить адрес":"Save address",
                    "Поиск адреса":"Address search",
                    "Укажите номер дома":"Insert house number",
                    "Редактировать адрес":"Edit address",
                    "Выбрать": "Select",
                    "Закажу здесь": "I'll order here",
                    "Выберите город": "Select a city",
                    "Выбрать заведение": "Select an establishment"
                }
            }
            
        },
        debug: false,

        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
